import { AppBar } from "components";
import {
  ButtonContent,
  Container,
  Content,
  ListRecklessTrader,
} from "./styles";
import {
  Add,
  Archive,
  Check,
  DoDisturb,
  Edit,
  Info,
  Save,
  Search,
} from "@mui/icons-material";
import {
  Grid,
  TextField,
  useTheme,
  Typography,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Zoom,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useAlert } from "contexts";
import { useEffect, useMemo, useRef, useState } from "react";
import { IRecklessTraderItem } from "types/entities";
import { RecklessTraderRisk, SearchType } from "types/enums";
import { useQuery } from "react-query";
import { DossierController, RecklessTraderController } from "controllers";
import {
  IAvailableExternalFontViewModel,
  IRecklessTraderViewModel,
} from "viewModels";
import { EditRecklessTrader } from "components/EditRecklessTrader";
import { Controller, useForm } from "react-hook-form";
import { useAuth, useRecklessTrader } from "contexts";
import { RecklessTraderItemsModal } from "components/Modal/RecklessTraderItemsModal";
import { EditRecklessTraderItemsModal } from "components/Modal/EditRecklessTraderModal";
import { MASK_CNPJ, MASK_CPF } from "../../constants";
import { toPattern } from "vanilla-masker";
import { v4 as uuid } from "uuid";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { getByCnpj } from "services/consultCnpj";
import { useNavigate } from "react-router-dom";

const RecklessTraderRowTable = ({
  index,
  recklessTrader,
  openModal,
  openEditModal,
}: {
  index: number;
  recklessTrader: IRecklessTraderViewModel;
  openModal: (recklessTrader: IRecklessTraderViewModel) => void;
  openEditModal: (recklessTrader: IRecklessTraderViewModel) => void;
}) => {
  const [disabled, setDisabled] = useState<boolean>(recklessTrader.disabled);

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography color="primary">{index}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{recklessTrader.document}</Typography>
        </TableCell>
        <TableCell>
          <Tooltip
            title={<h1 style={{ fontSize: 15 }}>{recklessTrader.name}</h1>}
            followCursor
            TransitionComponent={Zoom}
          >
            <Typography className="truncate">{recklessTrader.name}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Button
            style={{ padding: 0 }}
            onClick={() => {
              openModal(recklessTrader);
            }}
            variant="text"
          >
            <Typography>Ver Riscos</Typography>
          </Button>
        </TableCell>
        <TableCell>
          <Typography>{recklessTrader.createdAt}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{recklessTrader.createdBy}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            <IconButton
              onClick={() => {
                openEditModal(recklessTrader);
              }}
            >
              <Edit color="primary" />
            </IconButton>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            <IconButton
              onClick={() => {
                if (recklessTrader.id)
                  RecklessTraderController.Disabled(recklessTrader.id);
                setDisabled(!disabled);
              }}
            >
              {disabled ? (
                <DoDisturb color="error" />
              ) : (
                <Check color="success" />
              )}
            </IconButton>
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const RecklessTrader = () => {
  const alert = useAlert();
  const theme = useTheme();
  const { user } = useAuth();
  const [recklessTraderItemList, setRecklessTraderItemList] = useState<
    IRecklessTraderItem[]
  >([]);
  const [externalFonts, setExternalFonts] = useState<
    IAvailableExternalFontViewModel[]
  >([]);
  const [recklessTradersSelected, setRecklessTradersSelected] = useState<
    IRecklessTraderViewModel[]
  >([]);
  const { recklessTraders, setRecklessTraders } = useRecklessTrader();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [recklessTraderSelected, setRecklessTraderSelected] =
    useState<IRecklessTraderViewModel>({} as IRecklessTraderViewModel);
  const [mask, setMask] = useState("");
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [nameDisabled, setNameDisabled] = useState(false);
  const navigate = useNavigate();
  const listRef = useRef<HTMLDivElement>(null);
  const [externalFontsFiltered, setExternalFontsFiltered] =
    useState<IAvailableExternalFontViewModel[]>(externalFonts);

  const onDelete = (id: string) => {
    setRecklessTraderItemList(
      recklessTraderItemList.filter((r) => r.id !== id)
    );
  };
  const [defaultValues] = useState<IRecklessTraderViewModel>({
    name: "",
    document: "",
    createdAt: "",
    createdBy: "",
    recklessTraderItem: [],
    disabled: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });

  const data = watch();

  const onCnpj = () => {
    setMask(MASK_CNPJ);
    setValue("name", "");
    if (data.document.length === 18) handleSearchCompanyName();
  };

  useEffect(() => {
    setRecklessTradersSelected(recklessTraders);
  }, [recklessTraders]);

  useEffect(() => {
    data.document.length > 14 ? onCnpj() : setMask(MASK_CPF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.document]);

  useEffect(() => {
    data.document.length > 14
      ? setIsValidDocument(cnpj.isValid(data.document))
      : setIsValidDocument(cpf.isValid(data.document));
  }, [data.document]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    };

    handleScroll();
  }, [recklessTraderItemList]);

  const addRecklessTraderItem = () => {
    if (!data.document) {
      alert.show({
        type: "alert",
        title: `Erro`,
        timeout: 5000,
        description: "Insira um documento antes de prosseguir.",
      });
      return;
    }

    if (cnpj.isValid(data.document)) {
      const filteredFonts = externalFonts.filter(
        (font) =>
          font.value.type === SearchType.CNPJ ||
          font.value.type === SearchType.BOTH
      );
      setExternalFontsFiltered(filteredFonts);
    } else {
      const filteredFonts = externalFonts.filter(
        (font) =>
          font.value.type === SearchType.CPF ||
          font.value.type === SearchType.BOTH
      );
      setExternalFontsFiltered(filteredFonts);
    }

    setRecklessTraderItemList([
      ...recklessTraderItemList,
      {
        id: uuid(),
        name: "",
        externalFonts: [],
        risk: RecklessTraderRisk.LOW,
      },
    ]);
  };

  const handleSearchCompanyName = () => {
    if (data.document.length === 18) {
      getByCnpj(data.document.replace(/\D/g, ""))
        .then((e) => {
          setValue("name", e.companyName ?? "");
          if (e.companyName !== "") setNameDisabled(true);
        })
        .catch(() => {
          setNameDisabled(false);
        });
    }
  };

  const today = () => {
    const date = new Date();
    let day: string | number = date.getDate();
    let month: string | number = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return day + "/" + month + "/" + year;
  };

  const handleAddRecklessTrader = (
    recklessTrader: IRecklessTraderViewModel
  ) => {
    const hasEmptyName = recklessTraderItemList.some(
      (item) => item.name.trim() === ""
    );

    if (hasEmptyName) {
      alert.show({
        type: "alert",
        title: `Erro`,
        timeout: 5000,
        description: "Existe uma ou mais matriz sem nome.",
      });
      return;
    }

    recklessTrader.createdAt = today();
    recklessTrader.recklessTraderItem = recklessTraderItemList.filter(
      (r) => r.name.length >= 1
    );
    recklessTrader.createdBy = user ? user.name : "";
    RecklessTraderController.Insert({
      document: recklessTrader.document,
      name: recklessTrader.name,
      recklessTraderItem: recklessTrader.recklessTraderItem,
    }).then((id) => {
      setRecklessTraders([
        ...recklessTraders,
        {
          id: id,
          ...recklessTrader,
        },
      ]);
      reset();
      setRecklessTraderItemList([]);
      setRecklessTradersSelected([
        ...recklessTraders,
        {
          id: id,
          ...recklessTrader,
        },
      ]);
    });
  };

  useQuery(
    "external-fonts",
    () =>
      DossierController.getExternalFonts().then((res) => {
        setExternalFonts(
          res.data.filter((f) => f.value.type !== SearchType.DISABLED)
        );
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setRecklessTraderItemList([]);
  }, [data.document]);

  return (
    <>
      <AppBar />
      <Container>
        <Content>
          <Grid container spacing={2} padding="0 1rem">
            <Grid item xs={12}>
              <Typography
                component="p"
                variant="h4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
                color="primary"
              >
                Nova Matriz de Risco
                <Tooltip
                  data-testid="tooltipCDA"
                  title={
                    <h1 style={{ fontSize: "1rem" }}>
                      Uma matriz de risco é um método de avaliação que atribui
                      pesos específicos a diferentes riscos, permitindo uma
                      análise estruturada e comparativa para identificar as
                      áreas que requerem maior atenção.
                    </h1>
                  }
                  placement="top"
                  arrow
                >
                  <Info
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    style={{ fontSize: "1.25rem" }}
                  />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={12}>
              <form onSubmit={handleSubmit(handleAddRecklessTrader)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={12}>
                    <Controller
                      name="document"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Documento é obrigatório",
                        },
                        minLength: {
                          value: 14,
                          message: "Documento incompleto",
                        },
                        validate: {
                          validateDocument: () => {
                            return isValidDocument
                              ? true
                              : "Documento inválido";
                          },
                        },
                      }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          label="CPF ou CNPJ"
                          value={mask ? toPattern(value ?? "", mask) : mask}
                          error={!!errors.document?.message}
                          helperText={errors.document?.message}
                          onChange={onChange}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 120,
                          message: "Máximo de caracteres permitidos são 120",
                        },
                        required: {
                          value: true,
                          message: "Nome é obrigatório",
                        },
                      }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          label={
                            data.document.length > 14
                              ? "Nome da Pessoa Jurídica "
                              : "Nome da Pessoa Física"
                          }
                          value={value}
                          inputProps={{ maxLength: 64 }}
                          error={!!errors.name?.message}
                          helperText={errors.name?.message}
                          disabled={nameDisabled}
                          onChange={onChange}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h5" color="primary">
                      Listagem de riscos
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9} lg={8}>
                    <ListRecklessTrader data-testid="listRecklessTrader">
                      <div className="content" ref={listRef}>
                        {recklessTraderItemList.length > 0 ? (
                          recklessTraderItemList.map(
                            (recklessTrader, index) => (
                              <EditRecklessTrader
                                key={index}
                                onDelete={onDelete}
                                recklessTraderSelected={recklessTrader}
                                externalFonts={externalFontsFiltered}
                              />
                            )
                          )
                        ) : (
                          <Typography>
                            Nenhuma matriz de risco adicionada..
                          </Typography>
                        )}
                      </div>

                      <Button
                        className="endline"
                        variant="contained"
                        onClick={() => {
                          addRecklessTraderItem();
                        }}
                      >
                        <Add />
                        <Typography component="p" variant="subtitle1">
                          Adicionar
                        </Typography>
                      </Button>
                    </ListRecklessTrader>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonContent>
                      <Button variant="contained" type="submit">
                        <Save />
                        <Typography
                          component="p"
                          marginLeft={0.5}
                          variant="subtitle1"
                        >
                          Salvar
                        </Typography>
                      </Button>
                    </ButtonContent>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Content>

        <Content>
          <Grid container spacing={2} padding="0 1rem">
            <Grid item xs={12} xl={11}>
              <Typography component="p" variant="h4" color="primary">
                Matrizes de Risco
              </Typography>
            </Grid>
            <Grid item xs={12} xl={1}>
              <Button
                onClick={() => {
                  navigate("/recklessTraderReport");
                }}
                style={{
                  gap: 5,
                }}
              >
                <Archive />
                <Typography color="primary">Relatório</Typography>
              </Button>
            </Grid>
            <Grid item xs={8} sm={5} md={3} lg={2}>
              <TextField
                placeholder="Pesquisar.."
                fullWidth
                onChange={({ target: { value } }) => {
                  if (value === "") setRecklessTradersSelected(recklessTraders);
                  else
                    setRecklessTradersSelected(
                      recklessTraders.filter((r) =>
                        r.name
                          .toLocaleLowerCase()
                          .includes(value.toLocaleLowerCase())
                      )
                    );
                }}
                InputProps={{
                  sx: {
                    pl: 1,
                  },
                  endAdornment: <Search />,
                }}
                variant="standard"
              />
            </Grid>

            <Table className="table" stickyHeader aria-label="listDossiers">
              <TableHead>
                <TableRow
                  sx={{
                    th: {
                      backgroundColor: "white",
                      "& p": {
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      },
                      "& div": {
                        display: "flex",
                        alignItems: "center",

                        "& button": {
                          ml: 1,
                        },
                      },
                    },
                  }}
                >
                  <TableCell>
                    <Typography>#</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Documento</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Nome</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Riscos</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Data de criação</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Criado por</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Editar</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Habilitado</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recklessTradersSelected !== null &&
                  recklessTradersSelected.map((recklessTrader, index) => (
                    <RecklessTraderRowTable
                      index={index}
                      recklessTrader={recklessTrader}
                      key={index}
                      openEditModal={(recklessTraderS) => {
                        setRecklessTraderSelected(recklessTraderS);
                        setIsEditModalOpen(true);
                      }}
                      openModal={(recklessTraderS) => {
                        setRecklessTraderSelected(recklessTraderS);
                        setIsModalOpen(true);
                      }}
                    />
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Content>
      </Container>
      <RecklessTraderItemsModal
        isOpen={isModalOpen}
        onClose={() => {
          setRecklessTraderSelected({} as IRecklessTraderViewModel);
          setIsModalOpen(false);
        }}
        RecklessTraderSelected={recklessTraderSelected}
      />
      <EditRecklessTraderItemsModal
        isOpen={isEditModalOpen}
        externalFonts={externalFonts}
        onClose={() => {
          setRecklessTraderSelected({} as IRecklessTraderViewModel);
          setIsEditModalOpen(false);
        }}
        RecklessTraderSelected={recklessTraderSelected}
      />
    </>
  );
};

export default RecklessTrader;
