import {
  Grid,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IRecklessTraderItem } from "types/entities";
import { RecklessTraderRisk } from "types/enums";
import { IAvailableExternalFontViewModel } from "viewModels";
import { BrackLine, SelectExternalFonts } from "./style";
import { Delete, LabelImportant } from "@mui/icons-material";
import FilterInput from "components/FilterInput";

interface EditRecklessTraderProps {
  recklessTraderSelected: IRecklessTraderItem;
  externalFonts: IAvailableExternalFontViewModel[];
  onDelete: (id: string) => void;
}

export const EditRecklessTrader = ({
  recklessTraderSelected,
  externalFonts,
  onDelete,
}: EditRecklessTraderProps) => {
  const [externalFontsSearch, setExternalFontsSearch] = useState<
    IAvailableExternalFontViewModel[]
  >([]);
  const [search, setSearch] = useState<string>("");
  const [externalFontsSelected, setExternalFontsSelected] = useState<string[]>(
    recklessTraderSelected.externalFonts.map((e) => e.key)
  );
  const [selectAllText, setSelectAllText] = useState<string>("Marcar todos");

  const onSelected = (input: string) => {
    externalFontsSelected.includes(input)
      ? setExternalFontsSelected(
          externalFontsSelected.filter((e) => e !== input)
        )
      : setExternalFontsSelected([input, ...externalFontsSelected]);
  };

  useEffect(() => {
    setExternalFontsSearch(
      externalFonts.filter((e) =>
        e.value.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    recklessTraderSelected.externalFonts = externalFonts.filter((e) =>
      externalFontsSelected.includes(e.key)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalFontsSelected]);

  const handleSelectAll = () => {
    const allKeys = externalFontsSearch.map((font) => font.key);
    if (
      externalFontsSelected.length === externalFontsSearch.length &&
      externalFontsSelected.every((key) => allKeys.includes(key))
    ) {
      // Desmarcar todas as fontes externas selecionadas
      setExternalFontsSelected([]);
      setSelectAllText("Selecionar todos");
    } else {
      // Selecionar todas as fontes externas disponíveis
      setExternalFontsSelected(allKeys);
      setSelectAllText("Desmarcar todos");
    }
  };

  return (
    <>
      <Grid
        key={recklessTraderSelected.id}
        container
        spacing={1}
        padding="0 1rem"
        data-testid="GridEditRecklessTrader"
      >
        <Grid item xs={12} sm={4} lg={5}>
          <TextField
            size="small"
            fullWidth
            defaultValue={recklessTraderSelected.name}
            onChange={({ target }) =>
              (recklessTraderSelected.name = target.value)
            }
            label="Nome da matriz"
          />
        </Grid>
        <Grid item xs={11} sm={7} lg={6}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={recklessTraderSelected.risk}
            onChange={({ target }) => {
              recklessTraderSelected.risk = parseInt(target.value);
            }}
          >
            <div>
              <FormControlLabel
                value={RecklessTraderRisk.HIGH}
                control={<Radio />}
                label="Alto"
              />
              <FormControlLabel
                value={RecklessTraderRisk.MEDIUM}
                control={<Radio />}
                label="Médio"
              />
              <FormControlLabel
                value={RecklessTraderRisk.LOW}
                control={<Radio />}
                label="Baixo"
              />
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1} style={{ padding: 0, marginTop: 5 }}>
          <IconButton
            data-testid="deleteRecklessTrader"
            onClick={() => onDelete(recklessTraderSelected.id)}
          >
            <Delete color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ padding: 0, marginTop: 5 }}>
          <SelectExternalFonts>
            <div className="search">
              <div>
                <FilterInput
                  onInputChange={({ term }) => setSearch(term ?? "")}
                />
              </div>
              <Button variant="text" onClick={handleSelectAll}>
                <Typography>{selectAllText}</Typography>
              </Button>
            </div>
            <div className="items">
              <List dense sx={{ maxHeight: "100%" }}>
                <div className="grid">
                  {externalFontsSearch &&
                    externalFontsSearch.map((font) => (
                      <ListItem
                        data-testid="listFonts"
                        key={font.key}
                        onClick={() => onSelected(font.key)}
                        sx={{
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          borderRadius: 1,
                          userSelect: "none",
                          ":hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                          },
                        }}
                      >
                        <ListItemIcon
                          data-testid="iconFont"
                          sx={{ minWidth: 30 }}
                        >
                          <LabelImportant
                            color={
                              externalFontsSelected.includes(font.key)
                                ? "warning"
                                : "inherit"
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={font.value.name} />
                      </ListItem>
                    ))}
                </div>
              </List>
            </div>
          </SelectExternalFonts>
        </Grid>
        <BrackLine />
      </Grid>
    </>
  );
};
