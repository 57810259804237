/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Button, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Modal } from "components";
import { Container } from "./style";

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}: PrivacyPolicyModalProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [ref, setRef] = useState<HTMLDivElement>();

  const handleScroll = () => {
    if (ref) {
      const positionScroll = Math.round(ref.scrollTop + ref.clientHeight);
      const heightScroll = ref.scrollHeight;
      if (positionScroll >= heightScroll) {
        setIsDisabled(false);
      }
    }
  };

  const containerRef = useCallback((currentRef: HTMLDivElement) => {
    if (currentRef !== null) setRef(currentRef);
  }, []);

  useEffect(() => {
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }
  });

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Grid container justifyContent="space-between" padding="0.5rem 0">
        <Typography variant="h5" component="h2" color="primary">
          Política de privacidade
        </Typography>
        <IconButton size="small" onClick={() => onClose()} color="error">
          <Cancel />
        </IconButton>
      </Grid>
      <Container ref={containerRef}>
        <h1>Aceite dos Termos</h1>
        <li>
          <strong>Contextualização</strong>
        </li>
        <p>
          A Legal Audit possui o compromisso com a segurança e privacidade dos
          dados de todos os seus clientes, parceiros, fornecedores e terceiros
          interessados, adotando em todos os seus processos as medidas de
          segurança necessárias para a proteção destes ativos e respeitando os
          direitos de seus titulares.
        </p>
        <p>
          Com a intenção de melhorar e otimizar os produtos e serviços, a Legal
          Audit realiza o processamento de dados pessoais. Esta política de
          privacidade e segurança de dados tem por finalidade esclarecer e
          demonstrar aos seus usuários acerca do tratamento dos dados pessoais
          realizado, bem como quais informações poderão ser divulgadas ou
          compartilhadas com nossos parceiros.
        </p>
        <li>
          <strong>Aplicabilidade</strong>
        </li>
        <p>
          Ao utilizar a nossa plataforma, o usuário concorda e aceita, sem
          qualquer vício de consentimento, com esta Política de Privacidade,
          obrigando-se a respeitá-la em todos os seus termos e condições.
        </p>
        <p>
          Qualquer modificação em nossa Política de Privacidade será
          imediatamente informada aos usuários da Legal Audit.
        </p>
        <p>
          A presente Política de Privacidade é aplicada aos acessos realizados
          em qualquer website, aplicativos ou plataformas operadas pela Legal
          Audit. Este documento de privacidade pode ser complementado por
          declarações adicionais de privacidade, termos ou avisos a você
          fornecidos
        </p>
        <p>
          Caso o usuário não esteja de acordo com as disposições desta Política,
          é orientado a descontinuidade ao acesso ou uso do site, produtos e/ou
          serviços.
        </p>
        <li>
          <strong>Conceitos e Siglas</strong>
        </li>
        <h1>Dado Pessoal</h1>
        <p>
          Informação relacionada a uma pessoa identificada ou identificável.
          Isso pode incluir, mas não se limita a, nome, endereço, número de
          telefone, endereço de e-mail, entre outros.
        </p>
        <h1>Dado Pessoal Sensível</h1>
        <p>
          Dado pessoal referente a origem racial ou étnica, opinião política,
          convicção religiosa, dados referentes à saúde ou à vida sexual, dado
          genético ou biométrico quando vinculado a uma pessoa natural.
        </p>
        <h1>Privacidade</h1>
        <p>
          Direito de ser protegido uma interferência em assuntos pessoais e
          familiares.
        </p>
        <h1>LGPD</h1>
        <p>
          Lei Geral de Proteção de Dados, Lei n° 13.709/18, que dispõe sobre o
          tratamento de dados pessoais, inclusive nos meios digitais, por pessoa
          natural ou por pessoa jurídica de direito público ou privado, com o
          objetivo de proteger os direitos fundamentais de liberdade e de
          privacidade e o livre desenvolvimento da personalidade da pessoa
          natural.
        </p>
        <h1>Tratamento de Dados Pessoais</h1>
        <p>
          Qualquer operação tal como: coleta, produção, recepção, classificação,
          utilização, acessos, reprodução, transmissão, distribuição,
          processamento, arquivamento, armazenamento, eliminação, avaliação ou
          controle da informação, modificação, comunicação, transferência,
          difusão ou extração realizada com dados pessoais.
        </p>
        <h1>Controlador</h1>
        <p>
          Pessoa natural ou jurídica, de direito público ou privado a quem
          competem as decisões referentes ao tratamento de dados pessoais.
        </p>
        <h1>Operador</h1>
        <p>
          Pessoa natural ou jurídica, de direito público ou privado que realiza
          o tratamento de dados pessoais em nome do controlador.
        </p>
        <li>
          <strong>Do Tratamento de Dados pela Legal Audit.</strong>
        </li>
        <p>
          Os dados pessoais podem ser coletados pela interação e cadastramento
          no site ou outras plataformas da Legal Audit, assim como outras
          informações.
        </p>
        <table>
          <thead>
            <tr>
              <th>DADOS COLETADOS</th>
              <th>FINALIDADE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan={2}>DADOS DE CADASTRO</th>
            </tr>
            <tr>
              <td>
                <div>Nome completo</div>
                <div>CNPJ/CPF</div>
                <div>E-mail</div>
                <div>Data de Nascimento</div>
                <div>Endereço</div>
              </td>
              <td>
                <ul>
                  <li>Identificar e autenticar o usuário;</li>
                  <li>
                    Cumprir as obrigações decorrentes do uso dos nossos
                    serviços;
                  </li>
                  <li>
                    Otimizar, divulgar e promover novos produtos e serviços;
                  </li>
                  <li>Personalizar a experiência do usuário;</li>
                  <li>
                    Operar, mantar, aprimorar os recursos utilizados nos
                    serviços prestados, prestar suporte e responder comentários;
                  </li>
                  <li>
                    Garantir a portabilidade de dados, caso e quando solicitado;
                  </li>
                  <li>
                    Proteger o usuário contra potenciais fraudes e outros riscos
                    associados, bem como cumprir com as obrigações legais e
                    regulatórias aplicáveis.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>DADOS DE IDENTIFICAÇÃO DIGITAL</th>
            </tr>
            <tr>
              <td>
                <div>Endereço IP e Porta Lógica de Origem</div>
                <div>Informações do Navegador</div>
                <div>Registro de data e horário das operações</div>
                <div>ID da sessão</div>
                <div>Cookies</div>
              </td>
              <td>
                <ul>
                  <li>Identificar e autenticar o usuário;</li>
                  <li>Identificar e autenticar o usuário;</li>
                  <li>Coletar informações sobre a integração;</li>
                  <li>
                    Cumprimento de obrigações legais de manutenção de registros
                    estabelecidos pelo Marco Civil da Internet (Lei nº
                    12.965/14);
                  </li>
                  <li>
                    Analisar e entender tendências e preferências de uso,
                    visando a melhoria e personalização dos serviços;
                  </li>
                  <li>Prevenção de fraudes e outros riscos associados.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>UPAPI</th>
            </tr>
            <tr>
              <td>
                <div>Rota da API</div>
                <div>IP utilizado na requisição</div>
                <div>Corpo da requisição</div>
                <div>Corpo da resposta</div>
                <div>Horário da requisição</div>
                <div>Tempo de execução da requisição</div>
                <div>ID do usuário</div>
                <div>ID do cliente</div>
                <div>ID da chave de AAPI utilizada para fazer a requisição</div>
              </td>
              <td>
                <ul>
                  <li>Acompanhar parâmetros mais utilizados;</li>
                  <li>Acompanhamento de resposta do API;</li>
                  <li>Execução de rota/requisição solicitada;</li>
                  <li>Desenvolvimento de resposta requisitada;</li>
                  <li>Monitoramento de desempenho/execução;</li>
                  <li>Identificação do requisitante/cliente;</li>
                  <li>Auditoria.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          O não fornecimento das informações acima descritas poderá resultar na
          impossibilidade de prestar total ou parcialmente os serviços
          pretendidos.
        </p>
        <p>
          A Legal Audit não realiza o tratamento de dados sensíveis,
          limitando-se aos dados e informações necessárias para a prestação dos
          serviços, fornecimento do produto e divulgação destes.
        </p>
        <p>
          Os dados sensíveis, se futuramente forem necessários, observarão as
          hipóteses previstas na LGPD (Lei nº 13.709/18), sendo previamente
          informado ao usuário a respeito, do como como ocorrerá o tratamento e
          a finalidade deste.
        </p>
        <li>
          <strong>Responsabilidades</strong>
        </li>
        <p>
          É de responsabilidade do usuário a precisão, veracidade e atualização
          dos dados pretendidos.
        </p>
        <p>
          O usuário é igualmente responsável pelo sigilo de seus dados pessoais,
          e deve ter ciência de que o compartilhamento de senhas e dados de
          acesso viola a presente Política de Privacidade e pode comprometer a
          segurança de seus dados, website, bem como serviços e produtos.
        </p>
        <p>
          O tratamento de dados pela Legal Audit será realizado somente por
          profissionais devidamente autorizados, respeitando os princípios de
          proporcionalidade, necessidade e relevância para os objetivos do
          negócio. A Legal Audit possui compromisso com a confidencialidade e
          preservação da sua privacidade, nos termos desta Política.
        </p>
        <li>
          <strong>Cookies</strong>
        </li>
        <p>
          O acesso e utilização de nosso website, aplicativos, plataformas,
          produtos e serviços podem resultar no envio de um ou mais cookies para
          o seu dispositivo.
        </p>
        <p>
          Para saber como são utilizados os cookies e como gerenciá-los, acesso
          nossa Política de Cookies.
        </p>
        <li>
          <strong>Informações coletadas de fontes externas.</strong>
        </li>
        <p>
          A Legal Audit pode coletar informações, incluindo dados pessoais, de
          terceiros e outras fontes de dados, públicas e não públicas. Os dados
          poderão ser combinados ou associados a informações de outras fontes.
        </p>
        <p>
          O tratamento dos dados pessoais, combinados ou não, seguirão a
          presente política.
        </p>
        <p>
          <span>São as fontes públicas:</span>
        </p>
        <ul>
          <li>Registros e fontes públicas de dados;</li>
          <li>
            Informações do setor público, agências federais e órgãos
            reguladores;
          </li>
          <li>
            Listas nacionais e internacionais de sanções, listas de observação e
            PEP;
          </li>
          <li>Sites disponíveis na Internet;</li>
          <li>
            Pesquisas e API do Google ou de outro provedor de buscas da
            internet.{" "}
          </li>
        </ul>
        <p>
          <span>São as fontes não públicas:</span>
        </p>
        <ul>
          <li>
            O próprio titular dos dados, incluindo as informações fornecidas no
            site, plataforma e através de nossos produtos e serviços;
          </li>
          <li>Entidades comerciais;</li>
          <li>
            Fornecedores e parceiros de dados nacionais e internacionais.{" "}
          </li>
        </ul>
        <p>
          As informações são coletadas com a finalidade de viabilizar as
          operações do negócio, compreendendo, em especial, a gestão de risco e
          conformidades, por meio de suporte, processamento e fornecimento de
          informações que podem conter Dados Pessoais, seja dentro de um dossiê
          ou relatório de informações.
        </p>
        <p>
          As informações podem ser utilizadas para a o desenvolvimento de
          atividades de marketing e prospecção de novos negócios.
        </p>
        <li>
          <strong>Compartilhamento e divulgação das informações</strong>
        </li>
        <p>
          As informações coletadas e tratadas compreendem uma parte importante
          do nosso negócio e poderão ser compartilhados, mediante consentimento,
          nas seguintes circunstâncias:
        </p>
        <ul>
          <li>
            Prestadores de serviços terceirizados para o desenvolvimento de
            plataformas, aplicativos ou que apoiem no aprimoramento destes, bem
            como softwares e redes de armazenamento, transmissão, processamento
            de dados e outros relacionados à atividade da Legal Audit. O
            compartilhamento observará a finalidade e necessidade.
          </li>
          <li>
            Autoridades judiciais, administrativas ou governamentais
            competentes, sempre que houver uma determinação legal, requerimento,
            requisição ou ordem judicial prévia e específica.
          </li>
        </ul>
        <li>
          <strong>Compartilhamento e divulgação das informações</strong>
        </li>
        <p>
          Os dados coletados poderão ser armazenados em servidores locais e
          internacionais, tais como leste dos Estados Unidos, bem como em
          ambiente de uso de recursos ou servidores na nuvem (cloud computing),
          o que poderá exigir uma transferência e/ou processamento internacional
          destes dados.
        </p>
        <li>
          <strong>Armazenamento de dados.</strong>
        </li>
        <p>
          Todos os dados armazenados possuem uma finalidade legítima e
          previamente informada ao usuário, sendo armazenados apenas após o
          consentimento do usuário, nos termos da LGPD (Lei nº13.709/2018) e
          Marco Civil da Internet.
        </p>
        <p>
          Os dados armazenados observarão todas as medidas necessárias e
          adequadas para a sua proteção e salvaguarda dos direitos dos
          respectivos titulares, inclusive quanto a revogação do consentimento,
          quando requisitada.
        </p>
        <li>
          <strong>Direitos do Titular.</strong>
        </li>
        <p>
          O usuário poderá solicitar ao nosso encarregado de dados pessoais a
          confirmação da existência de tratamento de dados pessoais, além da
          exibição ou retificação de dados pessoais, por meio do nosso Canal de
          atendimento.
        </p>
        <p>Pelos canais de atendimento, você poderá também requerer:</p>
        <ul>
          <li>A limitação do uso de Dados Pessoais</li>
          <li>
            Manifestar sua oposição e/ou revogar o consentimento quanto ao uso
            de Dados Pessoais; ou
          </li>
          <li>
            Solicitar a exclusão de Dados Pessoais que tenham sidos coletados
            pela Legal Audit.
          </li>
        </ul>
        <p>
          A revogação do consentimento poderá implicar na indisponibilidade de
          funcionalidades próprias dos ambientes e serviços prestados pela Legal
          Audit. Na hipótese de revogação do consentimento, serão mantidos os
          dados necessários para cumprimento de obrigação legal, ou eventual
          necessidade de exercício regular do direito da Legal Audit.
        </p>
        <li>
          <strong>Retenção e Armazenamentos das Informações.</strong>
        </li>
        <p>
          A retenção de dados refere-se ao período das informações a serem
          armazenadas, mantidas ou preservada antes de serem descartadas. A
          Legal Audit reterá os dados de seus usuários segundo a atividade em
          conta.
        </p>
        <p>Após, os dados poderão ser armazenados pelo tempo legal cabível.</p>
        <p>
          As informações extraídas de fontes externas possuem o prazo de
          retenção e armazenamento conforme estabelecido nos seus respectivos
          termos e uso próprios. Assim, a Legal Audit não possui qualquer poder
          de caracterizar o modo como acontece o tratamento e a eliminação dos
          dados armazenados por estes.
        </p>
        <li>
          <strong>Revisões</strong>
        </li>
        <p>
          A presente política será atualizada anualmente ou a qualquer momento,
          mediante necessidade, alteração de fluxos, implementação de novas
          funcionalidades no sistema ou outros.
        </p>
        <li>
          <strong>Informações de Contato</strong>
        </li>
        <p>
          Eventuais questionamentos ou requisição, o titular de dados poderá
          entrar em contato com o DPO pelo e-mail{" "}
          <span>contato@legalcontrol.com.br.</span>
        </p>
        <h1>Versão 1 - 15/03/2023</h1>
      </Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Tooltip
          arrow
          placement="top"
          title={
            isDisabled ? "Leia todo os termos de políticas de privacidade" : ""
          }
        >
          <div>
            <Button disabled={isDisabled} onClick={() => handleConfirm()}>
              Aceitar
            </Button>
          </div>
        </Tooltip>
      </div>
    </Modal>
  );
};
