import { Loading } from "components";
import { CompanyUser, Error, Home, Logs, LogsUser, MultiTabError } from "pages";
import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import DossierModel from "pages/DossierModel";
import CollaboratorUser from "pages/CollaboratorUser";
import { ManagementStatus, TypeUser } from "types/enums";
import { useAuth, useCollaboratorUser } from "contexts";
import FinancialReport from "pages/FinancialReport";
import RecklessTrader from "pages/RecklessTrader";
import RecklessTraderReport from "pages/RecklessTraderReports";
import Balance from "pages/Balance";
import { AppVersion } from "components/AppVersion";

const Search = lazy(() => import("pages/Search"));

const Router = () => {
  const { user } = useAuth();
  const [anotherTab, setAnotherTab] = useState(false);
  const { collaboratorUserInfo } = useCollaboratorUser();

  useEffect(() => {
    const channel = new BroadcastChannel("legal-audit");
    channel.postMessage("another-tab");
    const timer = setTimeout(() => {
      channel.addEventListener("message", (msg) => {
        if (msg.data === "another-tab") {
          setAnotherTab(true);
        }
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {anotherTab ? (
        <MultiTabError />
      ) : (
        <BrowserRouter>
          <Suspense fallback={<Loading shown />}>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route
                path="/search"
                element={
                  <AuthRoute>
                    <Search />
                  </AuthRoute>
                }
              />
              <Route
                path="/balance"
                element={
                  <AuthRoute>
                    <Balance />
                  </AuthRoute>
                }
              />
              <Route
                path="/recklessTrader"
                element={
                  <AuthRoute>
                    <RecklessTrader />
                  </AuthRoute>
                }
              />
              <Route
                path="/recklessTraderReport"
                element={
                  <AuthRoute>
                    <RecklessTraderReport />
                  </AuthRoute>
                }
              />
              {/* <Route
                path="/relationsMap"
                element={
                  <AuthRoute>
                    <RelationMapProvider>
                      <RelationMap />
                    </RelationMapProvider>
                  </AuthRoute>
                }
              /> */}
              {user?.typeUser === TypeUser.ADM && (
                <>
                  <Route
                    path="/logs"
                    element={
                      <AuthRoute>
                        <Logs />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/logsUser"
                    element={
                      <AuthRoute>
                        <LogsUser />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/financialReport"
                    element={
                      <AuthRoute>
                        <FinancialReport />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/companies"
                    element={
                      <AuthRoute>
                        <CompanyUser />
                      </AuthRoute>
                    }
                  />
                </>
              )}
              {(user?.typeUser !== TypeUser.COLLABORATOR ||
                collaboratorUserInfo?.managementStatus.includes(
                  ManagementStatus.MODEL
                )) && (
                <>
                  {
                    <Route
                      path="/models"
                      element={
                        <AuthRoute>
                          <DossierModel />
                        </AuthRoute>
                      }
                    />
                  }
                </>
              )}
              {(user?.typeUser !== TypeUser.COLLABORATOR ||
                collaboratorUserInfo?.managementStatus.includes(
                  ManagementStatus.USER
                )) && (
                <>
                  {
                    <Route
                      path="/users"
                      element={
                        <AuthRoute>
                          <CollaboratorUser />
                        </AuthRoute>
                      }
                    />
                  }
                </>
              )}
              <Route path="*" element={<Error />} />
            </Routes>
          </Suspense>
          <AppVersion />
        </BrowserRouter>
      )}
    </>
  );
};

export default Router;
