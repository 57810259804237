import { Grid, Modal, TextField, Typography } from "@mui/material";
import { IRecklessTraderViewModel } from "viewModels";
import { Container, Content, Item, ListExternalFonts } from "./style";
import { ArrowDropDown, ArrowDropUp, Search } from "@mui/icons-material";
import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { IRecklessTraderItem } from "types/entities";

interface RecklessTraderItemsModalProps {
  isOpen: boolean;
  onClose: () => void;
  RecklessTraderSelected: IRecklessTraderViewModel;
}

interface RecklessTraderItemsProps {
  recklessTrader: IRecklessTraderItem;
}

const RecklessTraderItem = ({ recklessTrader }: RecklessTraderItemsProps) => {
  const theme = useTheme();
  const [isExternalFontsOpen, setIsExternalFontsOpen] =
    useState<boolean>(false);

  return (
    <Item data-testid="listRecklessTrader">
      <Grid container spacing={1} padding="0 1rem">
        <Grid item xs={8} md={9}>
          <Typography color={theme.colors.primary} variant="subtitle1">
            {recklessTrader.name}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="subtitle1">
            {recklessTrader.risk === 0
              ? "Baixo"
              : recklessTrader.risk === 1
              ? "Médio"
              : "Alto"}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <button
            onClick={() => {
              setIsExternalFontsOpen(!isExternalFontsOpen);
            }}
          >
            {isExternalFontsOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </button>
        </Grid>
        {isExternalFontsOpen ? (
          <Grid item xs={12} sm={6} md={12}>
            <ListExternalFonts>
              {recklessTrader.externalFonts.map((font, index) => (
                <Typography key={index} color={theme.colors.grey_300}>
                  {font.value.name}
                </Typography>
              ))}
            </ListExternalFonts>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Item>
  );
};

export const RecklessTraderItemsModal = ({
  isOpen,
  RecklessTraderSelected,
  onClose,
}: RecklessTraderItemsModalProps) => {
  const [recklessTraderItemSelected, setRecklessTraderItemSelected] = useState<
    IRecklessTraderItem[]
  >([]);

  useEffect(() => {
    if (RecklessTraderSelected.recklessTraderItem !== undefined)
      setRecklessTraderItemSelected(RecklessTraderSelected.recklessTraderItem);
  }, [RecklessTraderSelected.recklessTraderItem]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container>
        <div>
          <TextField
            placeholder="Pesquisar.."
            onChange={({ target: { value } }) => {
              if (value === "")
                setRecklessTraderItemSelected(
                  RecklessTraderSelected.recklessTraderItem
                );
              else
                setRecklessTraderItemSelected(
                  RecklessTraderSelected.recklessTraderItem.filter((r) =>
                    r.name
                      .toLocaleLowerCase()
                      .includes(value.toLocaleLowerCase())
                  )
                );
            }}
            InputProps={{
              sx: {
                pl: 1,
              },
              endAdornment: <Search />,
            }}
            variant="standard"
          />
        </div>
        <Content>
          {recklessTraderItemSelected.length > 0 ? (
            recklessTraderItemSelected.map((recklessTrader) => (
              <RecklessTraderItem
                key={recklessTrader.id}
                recklessTrader={recklessTrader}
              />
            ))
          ) : (
            <Typography>Nenhuma matriz de risco encontrada...</Typography>
          )}
        </Content>
      </Container>
    </Modal>
  );
};
