import { Button, Grid, Modal, TextField, Typography } from "@mui/material";
import {
  IAvailableExternalFontViewModel,
  IRecklessTraderViewModel,
} from "viewModels";
import { Container, ListRecklessTrader } from "./style";
import { EditRecklessTrader } from "components/EditRecklessTrader";
import { Add } from "@mui/icons-material";
import { RecklessTraderRisk } from "types/enums";
import { useEffect, useMemo, useRef, useState } from "react";
import { IRecklessTraderItem } from "types/entities";
import { Controller, useForm } from "react-hook-form";
import { RecklessTraderController } from "controllers";
import { v4 as uuid } from "uuid";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { getByCnpj } from "services/consultCnpj";
import { MASK_CNPJ, MASK_CPF } from "../../../constants";
import { toPattern } from "vanilla-masker";
import { useAlert } from "contexts";

interface EditRecklessTraderItemsModalProps {
  isOpen: boolean;
  onClose: () => void;
  RecklessTraderSelected: IRecklessTraderViewModel;
  externalFonts: IAvailableExternalFontViewModel[];
}

export const EditRecklessTraderItemsModal = ({
  isOpen,
  RecklessTraderSelected,
  onClose,
  externalFonts,
}: EditRecklessTraderItemsModalProps) => {
  const alert = useAlert();
  const [recklessTraderItems, setRecklessTraderItems] = useState<
    IRecklessTraderItem[]
  >([]);
  const [mask, setMask] = useState("");
  const [isCompanyNameFinded, setIsCompanyNameFinded] = useState(true);
  const [, setIsSearch] = useState(false);
  const [isCnpj, setIsCnpj] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const [defaultValues] = useState<IRecklessTraderViewModel>({
    name: "",
    document: "",
    createdAt: "",
    createdBy: "",
    recklessTraderItem: [],
    disabled: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });

  const data = watch();

  const onCnpj = () => {
    setMask(MASK_CNPJ);
    setValue("name", "");
    setIsCnpj(true);
    if (data.document.length === 18) handleSearchCompanyName();
  };

  const onCpf = () => {
    setMask(MASK_CPF);
    setIsCnpj(false);
  };

  const addRecklessTraderItem = () => {
    setRecklessTraderItems([
      ...recklessTraderItems,
      {
        id: uuid(),
        name: "",
        externalFonts: [],
        risk: RecklessTraderRisk.LOW,
      },
    ]);
  };

  const HandleEditSubmit = (input: IRecklessTraderViewModel) => {
    const hasEmptyName = recklessTraderItems.some(
      (item) => item.name.trim() === ""
    );

    if (hasEmptyName) {
      alert.show({
        type: "alert",
        title: `Erro`,
        timeout: 5000,
        description: "Existe uma ou mais matriz sem nome.",
      });
      return;
    }
    RecklessTraderController.Update({
      document: input.document,
      name: input.name,
      recklessTraderItem: recklessTraderItems,
      id: RecklessTraderSelected.id,
    }).then();
    RecklessTraderSelected.name = input.name;
    RecklessTraderSelected.recklessTraderItem = recklessTraderItems.filter(
      (r) => r.name.length >= 1
    );
    RecklessTraderSelected.document = input.document;
    onClose();
  };

  const handleSearchCompanyName = () => {
    if (data.document.length === 18) setIsSearch(true);
    getByCnpj(data.document.replace(/\D/g, ""))
      .then((e) => {
        e.companyName && clearErrors("name");
        setValue("name", e.companyName ?? "");
        setIsCompanyNameFinded(false);
        setIsSearch(false);
      })
      .catch(() => {
        setIsSearch(false);
      });
  };

  const onDelete = (id: string) => {
    setRecklessTraderItems(recklessTraderItems.filter((r) => r.id !== id));
  };

  useEffect(() => {
    if (!isOpen) {
      reset({
        name: "",
        document: "",
        createdAt: "",
        createdBy: "",
        recklessTraderItem: [],
        disabled: false,
      });
    } else {
      setRecklessTraderItems(RecklessTraderSelected.recklessTraderItem);
      setValue("name", RecklessTraderSelected.name);
      setValue("document", RecklessTraderSelected.document);
      setValue("createdAt", RecklessTraderSelected.createdAt);
      setValue("createdBy", RecklessTraderSelected.createdBy);
      setValue("disabled", RecklessTraderSelected.disabled);
    }
  }, [isOpen, RecklessTraderSelected, reset, setValue]);

  useEffect(() => {
    if (RecklessTraderSelected.document !== undefined) {
      data.document.length > 14 ? onCnpj() : onCpf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.document]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    };

    handleScroll();
  }, [recklessTraderItems]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container onSubmit={handleSubmit(HandleEditSubmit)}>
        <Grid container spacing={2} padding="0 1rem">
          <Grid item xs={12}>
            <Typography component="p" variant="h5" color="primary">
              Editar Matriz de Risco
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="document"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Documento é obrigatório",
                },
                minLength: {
                  value: 14,
                  message: "Documento incompleto",
                },
                validate: {
                  validateDocument: (e) => {
                    console.log(e);
                    const isValid = isCnpj
                      ? cnpj.isValid(e.replace(/\D/g, ""))
                      : cpf.isValid(e.replace(/\D/g, ""));
                    return isValid ? true : "Documento inválido";
                  },
                },
              }}
              render={({ field: { onChange, value, ...rest } }) => (
                <TextField
                  size="small"
                  fullWidth
                  label="Documento"
                  value={mask ? toPattern(value ?? "", mask) : mask}
                  error={!!errors.document?.message}
                  helperText={errors.document?.message}
                  onChange={onChange}
                  {...rest}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Nome é obrigatório",
                },
              }}
              render={({ field: { onChange, value, ...rest } }) => (
                <TextField
                  size="small"
                  fullWidth
                  disabled={isCnpj || isCompanyNameFinded}
                  label="Nome da empresa"
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  value={value}
                  onChange={onChange}
                  {...rest}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ListRecklessTrader>
              <div className="content" ref={listRef}>
                {recklessTraderItems && recklessTraderItems.length > 0 ? (
                  recklessTraderItems.map((recklessTrader, index) => (
                    <EditRecklessTrader
                      key={index}
                      recklessTraderSelected={recklessTrader}
                      onDelete={onDelete}
                      externalFonts={externalFonts}
                    />
                  ))
                ) : (
                  <Typography>Nenhuma matriz de risco adicionada..</Typography>
                )}
              </div>

              <Button
                className="endline"
                variant="contained"
                onClick={() => {
                  addRecklessTraderItem();
                }}
              >
                <Add />
                <Typography component="p" variant="subtitle1">
                  Adicionar
                </Typography>
              </Button>
            </ListRecklessTrader>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit">Salvar</Button>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};
